<template>
  <div
    class="two-columns-check-list"
    :class="[
      { 'two-columns-check-list--tiled': checkboxNested },
      themeColorClass
    ]"
  >
    <UiHeader
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <ul v-if="itemList.length" class="two-columns-check-list__list">
      <li
        v-for="item in itemList"
        :key="item.id"
        class="two-columns-check-list__item"
      >
        <div class="two-columns-check-list__item-header">
          <component
            :is="checkboxNested ? SvgIconCheckCircle : SvgIconCheck"
            class="two-columns-check-list__item-marker"
          />

          <T3HtmlParser
            v-if="item.name"
            :tag="item.text ? 'h3' : 'div'"
            class="two-columns-check-list__item-title"
            :content="item.name"
          />
        </div>

        <T3HtmlParser
          v-if="item.text"
          class="two-columns-check-list__item-text"
          :content="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~/types'
import SvgIconCheck from '~ui/assets/icons/icon-check.svg'
import SvgIconCheckCircle from '~ui/assets/new-icons/icon-check-circle.svg'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.two-columns-check-list {
  & .ui-header {
    margin-bottom: rem(32px);
    color: var(--color__font-color);
    font-weight: 600;

    &__title {
      margin-bottom: rem(16px);
    }

    border-bottom: 1px solid var(--color__border-color);
  }

  &--tiled .ui-header {
    border-bottom: none;
    border-top: 1px solid var(--color__border-color);

    &__title {
      margin-bottom: 0;
      margin-top: rem(16px);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-query(sm) {
      column-count: 2;
      column-gap: rem(40px);
      column-fill: balance;
    }
  }

  &--tiled &__list {
    @include media-query(sm) {
      column-gap: rem(20px);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    margin-bottom: rem(12px);

    &:last-child {
      margin-bottom: 0;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: rem(16px);
    }

    &-marker {
      flex-shrink: 0;
      margin-bottom: rem(2px);

      & path {
        fill: var(--theme__theme-color);
      }
    }

    &-title {
      font-size: rem(18px);
      color: var(--color__font-color--text);
      margin-block: auto;
    }

    &-text {
      @include themed-link;
    }
  }

  &--tiled &__item {
    display: inline-flex;
    padding: rem(16px);
    border: 1px solid var(--color__border-color);
    border-radius: rem(4px);
    margin-bottom: rem(20px);

    &-header {
      align-items: flex-start;
    }
  }
}
</style>
